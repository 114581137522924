/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer } from "ethers";
import { Provider } from "@ethersproject/providers";

import type { StableSwap } from "../StableSwap";

export class StableSwap__factory {
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): StableSwap {
    return new Contract(address, _abi, signerOrProvider) as StableSwap;
  }
}

const _abi = [
  {
    name: "TokenExchange",
    inputs: [
      {
        type: "address",
        name: "buyer",
        indexed: true,
      },
      {
        type: "int128",
        name: "sold_id",
        indexed: false,
      },
      {
        type: "uint256",
        name: "tokens_sold",
        indexed: false,
      },
      {
        type: "int128",
        name: "bought_id",
        indexed: false,
      },
      {
        type: "uint256",
        name: "tokens_bought",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "TokenExchangeUnderlying",
    inputs: [
      {
        type: "address",
        name: "buyer",
        indexed: true,
      },
      {
        type: "int128",
        name: "sold_id",
        indexed: false,
      },
      {
        type: "uint256",
        name: "tokens_sold",
        indexed: false,
      },
      {
        type: "int128",
        name: "bought_id",
        indexed: false,
      },
      {
        type: "uint256",
        name: "tokens_bought",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "AddLiquidity",
    inputs: [
      {
        type: "address",
        name: "provider",
        indexed: true,
      },
      {
        type: "uint256[2]",
        name: "token_amounts",
        indexed: false,
      },
      {
        type: "uint256[2]",
        name: "fees",
        indexed: false,
      },
      {
        type: "uint256",
        name: "invariant",
        indexed: false,
      },
      {
        type: "uint256",
        name: "token_supply",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "RemoveLiquidity",
    inputs: [
      {
        type: "address",
        name: "provider",
        indexed: true,
      },
      {
        type: "uint256[2]",
        name: "token_amounts",
        indexed: false,
      },
      {
        type: "uint256[2]",
        name: "fees",
        indexed: false,
      },
      {
        type: "uint256",
        name: "token_supply",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "RemoveLiquidityImbalance",
    inputs: [
      {
        type: "address",
        name: "provider",
        indexed: true,
      },
      {
        type: "uint256[2]",
        name: "token_amounts",
        indexed: false,
      },
      {
        type: "uint256[2]",
        name: "fees",
        indexed: false,
      },
      {
        type: "uint256",
        name: "invariant",
        indexed: false,
      },
      {
        type: "uint256",
        name: "token_supply",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "CommitNewAdmin",
    inputs: [
      {
        type: "uint256",
        name: "deadline",
        indexed: true,
        unit: "sec",
      },
      {
        type: "address",
        name: "admin",
        indexed: true,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "NewAdmin",
    inputs: [
      {
        type: "address",
        name: "admin",
        indexed: true,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "CommitNewParameters",
    inputs: [
      {
        type: "uint256",
        name: "deadline",
        indexed: true,
        unit: "sec",
      },
      {
        type: "uint256",
        name: "A",
        indexed: false,
      },
      {
        type: "uint256",
        name: "fee",
        indexed: false,
      },
      {
        type: "uint256",
        name: "admin_fee",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "NewParameters",
    inputs: [
      {
        type: "uint256",
        name: "A",
        indexed: false,
      },
      {
        type: "uint256",
        name: "fee",
        indexed: false,
      },
      {
        type: "uint256",
        name: "admin_fee",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "get_virtual_price",
    outputs: [
      {
        type: "uint256",
        name: "out",
      },
    ],
    inputs: [],
    constant: true,
    payable: false,
    type: "function",
  },
  {
    name: "calc_token_amount",
    outputs: [
      {
        type: "uint256",
        name: "out",
      },
    ],
    inputs: [
      {
        type: "uint256[2]",
        name: "amounts",
      },
      {
        type: "bool",
        name: "deposit",
      },
    ],
    constant: true,
    payable: false,
    type: "function",
  },
  {
    name: "get_dy",
    outputs: [
      {
        type: "uint256",
        name: "out",
      },
    ],
    inputs: [
      {
        type: "int128",
        name: "i",
      },
      {
        type: "int128",
        name: "j",
      },
      {
        type: "uint256",
        name: "dx",
      },
    ],
    constant: true,
    payable: false,
    type: "function",
  },
  {
    name: "get_dy_underlying",
    outputs: [
      {
        type: "uint256",
        name: "out",
      },
    ],
    inputs: [
      {
        type: "int128",
        name: "i",
      },
      {
        type: "int128",
        name: "j",
      },
      {
        type: "uint256",
        name: "dx",
      },
    ],
    constant: true,
    payable: false,
    type: "function",
  },
  {
    name: "coins",
    outputs: [
      {
        type: "address",
        name: "out",
      },
    ],
    inputs: [
      {
        type: "int128",
        name: "arg0",
      },
    ],
    constant: true,
    payable: false,
    type: "function",
  },
  {
    name: "underlying_coins",
    outputs: [
      {
        type: "address",
        name: "out",
      },
    ],
    inputs: [
      {
        type: "int128",
        name: "arg0",
      },
    ],
    constant: true,
    payable: false,
    type: "function",
  },
  {
    name: "balances",
    outputs: [
      {
        type: "uint256",
        name: "out",
      },
    ],
    inputs: [
      {
        type: "uint256",
        name: "arg0",
      },
    ],
    constant: true,
    payable: false,
    type: "function",
  },
  {
    name: "A",
    outputs: [
      {
        type: "uint256",
        name: "out",
      },
    ],
    inputs: [],
    constant: true,
    payable: false,
    type: "function",
  },
  {
    name: "fee",
    outputs: [
      {
        type: "uint256",
        name: "out",
      },
    ],
    inputs: [],
    constant: true,
    payable: false,
    type: "function",
  },
  {
    name: "admin_fee",
    outputs: [
      {
        type: "uint256",
        name: "out",
      },
    ],
    inputs: [],
    constant: true,
    payable: false,
    type: "function",
  },
  {
    name: "owner",
    outputs: [
      {
        type: "address",
        name: "out",
      },
    ],
    inputs: [],
    constant: true,
    payable: false,
    type: "function",
  },
  {
    name: "admin_actions_deadline",
    outputs: [
      {
        type: "uint256",
        unit: "sec",
        name: "out",
      },
    ],
    inputs: [],
    constant: true,
    payable: false,
    type: "function",
  },
  {
    name: "transfer_ownership_deadline",
    outputs: [
      {
        type: "uint256",
        unit: "sec",
        name: "out",
      },
    ],
    inputs: [],
    constant: true,
    payable: false,
    type: "function",
  },
  {
    name: "future_A",
    outputs: [
      {
        type: "uint256",
        name: "out",
      },
    ],
    inputs: [],
    constant: true,
    payable: false,
    type: "function",
  },
  {
    name: "future_fee",
    outputs: [
      {
        type: "uint256",
        name: "out",
      },
    ],
    inputs: [],
    constant: true,
    payable: false,
    type: "function",
  },
  {
    name: "future_admin_fee",
    outputs: [
      {
        type: "uint256",
        name: "out",
      },
    ],
    inputs: [],
    constant: true,
    payable: false,
    type: "function",
  },
  {
    name: "future_owner",
    outputs: [
      {
        type: "address",
        name: "out",
      },
    ],
    inputs: [],
    constant: true,
    payable: false,
    type: "function",
  },
];
