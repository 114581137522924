/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer } from "ethers";
import { Provider } from "@ethersproject/providers";

import type { CurveDeposit } from "../CurveDeposit";

export class CurveDeposit__factory {
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): CurveDeposit {
    return new Contract(address, _abi, signerOrProvider) as CurveDeposit;
  }
}

const _abi = [
  {
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "_pool",
      },
      {
        type: "address",
        name: "_token",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    name: "add_liquidity",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "uint256[4]",
        name: "amounts",
      },
      {
        type: "uint256",
        name: "min_mint_amount",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "remove_liquidity",
    outputs: [
      {
        type: "uint256[4]",
        name: "",
      },
    ],
    inputs: [
      {
        type: "uint256",
        name: "_amount",
      },
      {
        type: "uint256[4]",
        name: "min_amounts",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "remove_liquidity_one_coin",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "uint256",
        name: "_token_amount",
      },
      {
        type: "int128",
        name: "i",
      },
      {
        type: "uint256",
        name: "_min_amount",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "remove_liquidity_imbalance",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "uint256[4]",
        name: "amounts",
      },
      {
        type: "uint256",
        name: "max_burn_amount",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "calc_withdraw_one_coin",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "uint256",
        name: "_token_amount",
      },
      {
        type: "int128",
        name: "i",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "calc_token_amount",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "uint256[4]",
        name: "amounts",
      },
      {
        type: "bool",
        name: "is_deposit",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "pool",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "token",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "base_pool",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "coins",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [
      {
        type: "uint256",
        name: "arg0",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "base_coins",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [
      {
        type: "uint256",
        name: "arg0",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];
