/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer } from "ethers";
import { Provider } from "@ethersproject/providers";

import type { TokenMinter } from "../TokenMinter";

export class TokenMinter__factory {
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): TokenMinter {
    return new Contract(address, _abi, signerOrProvider) as TokenMinter;
  }
}

const _abi = [
  {
    name: "Minted",
    inputs: [
      {
        type: "address",
        name: "recipient",
        indexed: true,
      },
      {
        type: "address",
        name: "gauge",
        indexed: false,
      },
      {
        type: "uint256",
        name: "minted",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "_token",
      },
      {
        type: "address",
        name: "_controller",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    name: "mint",
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "gauge_addr",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "mint_many",
    outputs: [],
    inputs: [
      {
        type: "address[8]",
        name: "gauge_addrs",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "mint_for",
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "gauge_addr",
      },
      {
        type: "address",
        name: "_for",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "toggle_approve_mint",
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "minting_user",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "token",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "controller",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "minted",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "arg0",
      },
      {
        type: "address",
        name: "arg1",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "allowed_to_mint_for",
    outputs: [
      {
        type: "bool",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "arg0",
      },
      {
        type: "address",
        name: "arg1",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];
