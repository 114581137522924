/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer } from "ethers";
import { Provider } from "@ethersproject/providers";

import type { MusdGauge } from "../MusdGauge";

export class MusdGauge__factory {
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MusdGauge {
    return new Contract(address, _abi, signerOrProvider) as MusdGauge;
  }
}

const _abi = [
  {
    name: "Deposit",
    inputs: [
      {
        type: "address",
        name: "provider",
        indexed: true,
      },
      {
        type: "uint256",
        name: "value",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "Withdraw",
    inputs: [
      {
        type: "address",
        name: "provider",
        indexed: true,
      },
      {
        type: "uint256",
        name: "value",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "UpdateLiquidityLimit",
    inputs: [
      {
        type: "address",
        name: "user",
        indexed: false,
      },
      {
        type: "uint256",
        name: "original_balance",
        indexed: false,
      },
      {
        type: "uint256",
        name: "original_supply",
        indexed: false,
      },
      {
        type: "uint256",
        name: "working_balance",
        indexed: false,
      },
      {
        type: "uint256",
        name: "working_supply",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "CommitOwnership",
    inputs: [
      {
        type: "address",
        name: "admin",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "ApplyOwnership",
    inputs: [
      {
        type: "address",
        name: "admin",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "lp_addr",
      },
      {
        type: "address",
        name: "_minter",
      },
      {
        type: "address",
        name: "_reward_contract",
      },
      {
        type: "address",
        name: "_rewarded_token",
      },
      {
        type: "address",
        name: "_admin",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    name: "user_checkpoint",
    outputs: [
      {
        type: "bool",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "addr",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "claimable_tokens",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "addr",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "claimable_reward",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "addr",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "kick",
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "addr",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "set_approve_deposit",
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "addr",
      },
      {
        type: "bool",
        name: "can_deposit",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "deposit",
    outputs: [],
    inputs: [
      {
        type: "uint256",
        name: "_value",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "deposit",
    outputs: [],
    inputs: [
      {
        type: "uint256",
        name: "_value",
      },
      {
        type: "address",
        name: "addr",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "withdraw",
    outputs: [],
    inputs: [
      {
        type: "uint256",
        name: "_value",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "withdraw",
    outputs: [],
    inputs: [
      {
        type: "uint256",
        name: "_value",
      },
      {
        type: "bool",
        name: "claim_rewards",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "claim_rewards",
    outputs: [],
    inputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "claim_rewards",
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "addr",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "integrate_checkpoint",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "kill_me",
    outputs: [],
    inputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "commit_transfer_ownership",
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "addr",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "apply_transfer_ownership",
    outputs: [],
    inputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "toggle_external_rewards_claim",
    outputs: [],
    inputs: [
      {
        type: "bool",
        name: "val",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "minter",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "crv_token",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "lp_token",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "controller",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "voting_escrow",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "balanceOf",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "arg0",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "totalSupply",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "future_epoch_time",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "approved_to_deposit",
    outputs: [
      {
        type: "bool",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "arg0",
      },
      {
        type: "address",
        name: "arg1",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "working_balances",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "arg0",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "working_supply",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "period",
    outputs: [
      {
        type: "int128",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "period_timestamp",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "uint256",
        name: "arg0",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "integrate_inv_supply",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "uint256",
        name: "arg0",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "integrate_inv_supply_of",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "arg0",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "integrate_checkpoint_of",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "arg0",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "integrate_fraction",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "arg0",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "inflation_rate",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "reward_contract",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "rewarded_token",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "reward_integral",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "reward_integral_for",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "arg0",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "rewards_for",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "arg0",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "claimed_rewards_for",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "arg0",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "admin",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "future_admin",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "is_killed",
    outputs: [
      {
        type: "bool",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "is_claiming_rewards",
    outputs: [
      {
        type: "bool",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
];
